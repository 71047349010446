@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
:root {
    --background0: #fff9ef;
    --background1: #000333;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
body {
    background: var(--background0);
    min-height: 100vh;
    justify-content: flex-end;
    align-items: flex-end;
    overflow-x: hidden;
}
a {
    text-decoration: none;
    color: #0136b1;
}
a:hover {
    cursor: pointer;
    color: #616161;
}
h3 a {
    color: #000;
}
body::-webkit-scrollbar-track {
    background-color: #1e1e1e;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
}

body::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}
p {
    font-size: 1em;
    line-height: 1.75em;
}

.info {
    display: grid;
    margin: 30px 55px;
    background-color: #ebe5dc;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 15px;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}
.info1 {
    grid-template-columns: 1fr;
}
.info p {
    font-size: 1em;
}
.info video{
    width: 100%;
    border-radius: 15px;
    align-self: center;
}
.info img {
    width: 100%;
    border-radius: 15px;
    right: 0;
    display: -webkit-box;
    margin-left: auto;
    align-self: center;

}
.no {
    transition: none;
}
#impressum,
#policies,
#team {
    padding-left: 12px;
    padding-right: 12px;
}

/*Handy*/
@media only screen and (min-width: 320px) and (max-width: 480px) {
    body {
        overflow-x: hidden;
    }
    .info {
        display: block;
    }
    .info img,
    .info video {
        width: 100%;
    }
    .header .logo {
        display: none;
    }
    footer .menu li a {
        font-size: 0.9em
    }
    .main0 div h2 {
        font-weight: 100;
        font-size: 8.5vw;
        animation: 1.5s ease-out 0s 1 fadeIn;
    }
    .main0 div h1 {
        font-weight: 100;
        font-size: 14vw;
        animation: 3.5s ease-out 0s 1 fadeIn;
    }
    .navbar0 header .logo img, .navbar1 header .logo img, .navbar2 header .logo img {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }
    .navbar0 header .logo h1, .navbar1 header .logo h1, .navbar2 header .logo h1 {
        display: none;
    }
    .nav0 {
        gap: 10px;
        font-size: 2.15vw;
    }
    .nav0 li {
        white-space: nowrap;
    }
    .info p {
        font-size: 0.9em;
    }
    p {
        line-height: 1.5em;
    }
}
/*Tablets*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
    .info {
        display: block;
    }
    .info img,
    .info video {
        width: 100%;
    }
    .header .logo {
        display: none;
    }
    footer .menu li a {
        font-size: 1.25em
    }
    .main0 div h2 {
        font-weight: 100;
        font-size: 8.5vw;
        animation: 1.5s ease-out 0s 1 fadeIn;
    }
    .main0 div h1 {
        font-weight: 100;
        font-size: 14vw;
        animation: 3.5s ease-out 0s 1 fadeIn;
    }
    .navbar0 header .logo img, .navbar1 header .logo img, .navbar2 header .logo img {
        width: 55px;
        height: 55px;
        border-radius: 15px;
    }
    .navbar0 header .logo h1, .navbar1 header .logo h1, .navbar2 header .logo h1 {
        font-size: 1.6em;
    }
    .nav0 {
        gap: 10px;
        font-size: 2.5vw;
    }
    .nav0 li {
        white-space: nowrap;
    }
    .info p {
        font-size: 1.25em;
    }
    p {
        line-height: 1.75em;
    }
}
/*Laptops*/
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    body {
        overflow-x: hidden;
    }
    .info {
        display: block;
    }
    .info img,
    .info video {
        width: 100%;
    }
    .header .logo {
        display: none;
    }
    footer .menu li a {
        font-size: 1em
    }
    .main0 div h2 {
        font-weight: 100;
        animation: 1.5s ease-out 0s 1 fadeIn;
    }
    .main0 div h1 {
        font-weight: 100;
        font-size: 10vw;
        animation: 3.5s ease-out 0s 1 fadeIn;
    }
    .navbar0 header .logo img, .navbar1 header .logo img, .navbar2 header .logo img {
        width: 55px;
        height: 55px;
        border-radius: 15px;
    }
    .navbar0 header .logo h1, .navbar1 header .logo h1, .navbar2 header .logo h1 {
        font-size: 1.6em;
    }
    .nav0 {
        gap: 10px;
        font-size: 1.75vw;
    }
    .nav0 li {
        white-space: nowrap;
    }
    .info p {
        font-size: 1em;
    }
    p {
        line-height: 1.75em;
    }
}
/*Desktop*/
@media only screen and (min-width: 1025px) and (max-width: 1200px) {}
/*Standard*/
@media only screen and (min-width: 1201px) {}